/* #content {
    width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;} */

    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;900&display=swap');

    html {
        scroll-behavior: smooth;
       }

    body {  
    background-color: #1B2223;
    font-family: 'poppins', sans-serif;
    font-size: medium;
    color: #F4FEFD;
    scroll-behavior: smooth;
    } 

a, a:hover, a:focus, a:active {
        text-decoration: none;
        color: inherit;}

/* Animation */

.fade-in-section {
    opacity: 0;
    transform: translateY(10vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

/* NAVIGATION BAR */

#navigation-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0px;
    overflow: hidden;
    background-color: #1B2223;
    z-index: 1000;
}

#navigation-bar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #1B2223;
    /* position: relative; */
    /* margin-top: 12px;
    margin-bottom: 12px; */
}

#navigation-bar li {
    float: left;
    display: inline;
    margin-top: 0px;
    vertical-align: middle;
}

#navigation-bar a {
    display: block;
    color: #F4FEFD;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;}

#navigation-bar li a:hover {
    background-color: #0EF6CC;
    color: #3A4F50;
    transition: .5s ease-in-out;
}

#navigation-bar .social-icons {
    float: right;
    display: block;
    text-align: center;
}

#intro {
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15%;
    padding-top: 10%;
    min-height: 75vh;
}

#home {
    position: relative;
    padding-top: 0px;
    padding-bottom: 0px;
    transition: all 0.5s;
    -webkit-text-stroke: 4px #d6f4f4;
    font-variation-settings: "wght" 900, "ital" 1;
    font-size: 15rem;
    text-align: center;
    color: transparent;
    font-family: 'popins', sans-serif;
    text-shadow: 10px 10px 0px #0EF6CC, 
                15px 15px 0px #e601c0,
                20px 20px 0px #e9019a,
                25px 25px 0px #f40468,
                45px 45px 0px #482896;
    cursor: pointer;
}

#home:hover {
    -webkit-text-stroke: 3px #d6f4f4;
    font-variation-settings: "wght" 100, "ital" 0;
    text-shadow: none;
}

#about-me{
    text-align: center;
    font-size: 22px;
    max-width: 40%;
    text-align: justify;

}

#experience {
    text-align: center;
    font-size: 22px;
    max-width: 40%;
    text-align: justify;
    padding-left: 15%;
    /* min-height: 100vh; */
}

/* FOOTER */
#footer {
    display: flex;
    align-items: center;
    padding-top: 100px;
}

.ending-credits {
    font-family: 'popins', sans-serif;
    color: #F4FEFD;
    text-align: center;
    width: 100%;
    font-size: 12px;
    padding-bottom: 20px;
}

/* e-mail button */
.intro-contact {
    text-align: center;
    font-size: 22px;
    color: #F4FEFD;
}

.email-me ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.email-me li {
    float: center;
    display: inline;
    text-align: center;
}

/* Buttons */
.email-me-button{
    cursor: pointer;
}
.email-me {
    background-color: #3A4F50; 
    border: 2px solid #0EF6CC;
    color: rgb(255 255 255); 
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'poppins', sans-serif;
    font-size: 20px;
    margin: 2px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
  }
.email-me:hover {
    background-color: #0EF6CC;
    color: #1B2223;
    transition : .5s ease-in-out;
  }

  /* Media query for screens smaller than 600px */
@media only screen and (max-width: 600px) {
    #intro {
      padding-left: 5%; 
      padding-top: 5%;
    }
  
    #home {
      font-size: 8rem;
    }
  
    #about-me,
    #experience {
      max-width: 80%; 
    }
  
    #navigation-bar {
      padding: 0; 
    }
  
    #navigation-bar a {
      padding: 10px 12px; 
    }
  
    .email-me {
      font-size: 16px; 
      padding: 8px 16px; 
    }
  }