/* Carousel.css */
#projects-section {
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15%;
    /* padding-top: 10%; */
    /* min-height: 75vh; */
}

.carousel-container {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 60px;
  }
  
  .carousel-slide {
    position: relative;
    transition: transform 0.3s ease-in-out;
  }
  
  .carousel-slide:hover {
    transform: scale(1.05);
  }
  
  .carousel-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 24px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    opacity: 0.4;
  }
  
  .carousel-content {
    position: absolute;
    bottom: 0;
    left: 25%;
    text-align: center;
    color: #F4FEFD;
    max-width: 500px;
    padding: 20px;
    user-select: none;
    z-index: 999;
  }
  
  .carousel-title {
    font-family: "NTR", sans-serif;
    font-size: 38px;
    font-weight: bold;
    color: #F4FEFD;
  }
  
  .carousel-description {
    font-family: "NTR", sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #F4FEFD;
    padding-bottom: 20px;
  }
  
  .carousel-technologies {
    font-family: "NTR", sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #0EF6CC;
  }
  
  .carousel-indicators .active {
    background-color: #F4FEFD;
  }
  
  .github-button {
    width: 50px;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 600px) {
    .carousel-container {
      height: unset;
      padding-left: unset;
      padding-right: unset;
    }
    .carousel {
      display: none;
    }
  }